.app {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    .app-header {
        display: flex;
        min-height: 40px;
        flex-direction: column;
    }
    .app-content {
        flex: 1 1 calc(100vh - 100px);
        display: flex;
        overflow: auto;
        position: relative;
        h1 {
            font-size: 18pt;
            font-weight: bold;
            color: #666;
        }
    }
    .app-footer {
        font-size: 11px;
        padding: 10px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
}
